import React, { useState, useEffect } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../firebase';
import { useTranslation } from 'react-i18next';
import TeamCreation from '../components/TeamCreation';
import CheckoutForm from '../components/CheckoutForm';

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const db = getFirestore();

  // États
  const [currentStep, setCurrentStep] = useState('auth');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Vérification de l'état initial
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setEmail(user.email);
        
        try {
          const teamRef = doc(db, 'teams', user.uid);
          const teamDoc = await getDoc(teamRef);
          
          if (teamDoc.exists()) {
            const team = teamDoc.data();
            
            if (team.paymentStatus === 'pending') {
              setTeamData(team);
              setCurrentStep('payment');
            } else if (team.paymentStatus === 'completed') {
              navigate('/tableaubord');
            } else {
              setCurrentStep('team');
            }
          } else {
            setCurrentStep('team');
          }
        } catch (error) {
          console.error('Erreur vérification équipe:', error);
          setError(t('auth.verificationError'));
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate, db, t]);

  // Gestion du retour de paiement Stripe
  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId && auth.currentUser && teamData) {
      handlePaymentVerification(sessionId);
    }
  }, [searchParams, teamData]);

  // Création de l'équipe
  const handleTeamCreation = async (formData) => {
    setLoading(true);
    try {
      const userId = auth.currentUser.uid;
      const teamRef = doc(db, 'teams', userId);

      const team = {
        ...formData,
        captainEmail: email,
        createdAt: new Date(),
        currentLevel: 1,
        currentStage: 'Virtuel',
        usedLevels: [],
        bonusPoints: 0,
        points: 0,
        emailVerified: auth.currentUser.emailVerified,
        userId: userId,
        paymentStatus: 'pending'
      };

      await setDoc(teamRef, team);
      setTeamData(team);
      setCurrentStep('payment');
    } catch (error) {
      setError(t('teamCreation.errorSaving'));
      console.error('Erreur création équipe:', error);
    } finally {
      setLoading(false);
    }
  };

  // Vérification du paiement
  const handlePaymentVerification = async (sessionId) => {
    setLoading(true);
    try {
      const response = await fetch("https://us-central1-rallye-bacalar.cloudfunctions.net/verifyPayment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId: sessionId.split('?')[0],
          userId: auth.currentUser.uid
        }),
      });

      const data = await response.json();
      
      if (data.success) {
        await updateDoc(doc(db, 'teams', auth.currentUser.uid), {
          paymentStatus: 'completed',
          paymentDate: new Date()
        });
        navigate('/tableaubord');
      } else {
        setError(t('payment.verificationFailed'));
        setCurrentStep('payment');
      }
    } catch (error) {
      setError(t('payment.verificationError'));
      console.error('Erreur vérification:', error);
    } finally {
      setLoading(false);
    }
  };

  // Connexion Google
  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setEmail(result.user.email);
      setCurrentStep('team');
    } catch (error) {
      setError(t('auth.googleError'));
      console.error('Erreur Google:', error);
    } finally {
      setLoading(false);
    }
  };

  // Inscription email/password
  const handleEmailSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setCurrentStep('team');
    } catch (error) {
      setError(t('auth.emailError'));
      console.error('Erreur email:', error);
    } finally {
      setLoading(false);
    }
  };

  // Rendu des étapes
  const renderCurrentStep = () => {
    if (loading) {
      return (
        <div className="text-center py-8">
          <span className="text-blue-500">{t('common.loading')}</span>
        </div>
      );
    }

    switch (currentStep) {
      case 'auth':
        return (
          <div className="space-y-6">
            <button
              onClick={handleGoogleSignup}
              disabled={loading}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-400"
            >
              {t('auth.googleSignup')}
            </button>

            <div className="text-center text-gray-500">{t('auth.login.or')}</div>

            <form onSubmit={handleEmailSignup} className="space-y-4">
              <div>
                <label htmlFor="email" className="block mb-1">{t('auth.login.emailPlaceholder')}</label>
                <input
                  id="email"
                  type="email"
                  placeholder={t('auth.login.emailPlaceholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-2 border rounded"
                  disabled={loading}
                />
                {!email && <span className="text-red-500 text-sm">{t('auth.login.emailRequired')}</span>}
              </div>

              <div>
                <label htmlFor="password" className="block mb-1">{t('auth.login.passwordLabel')}</label>
                <input
                  id="password"
                  type="password"
                  placeholder={t('auth.login.passwordPlaceholder')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-2 border rounded"
                  disabled={loading}
                />
                {!password && <span className="text-red-500 text-sm">{t('auth.login.passwordRequired')}</span>}
              </div>

              <div>
                <label>
                  <input type="checkbox" className="mr-1" />
                  {t('auth.login.rememberMe')}
                </label>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-400"
              >
                {t('auth.emailSignup')}
              </button>

              <div className="text-right">
                <a href="/mot-de-passe-oublie" className="text-sm text-blue-500 hover:underline">
                  {t('auth.login.forgotPassword')}
                </a>
              </div>
            </form>
          </div>
        );

      case 'team':
        return (
          <>
            <div className="mb-6 bg-blue-50 border border-blue-200 text-blue-700 px-4 py-3 rounded">
              <p className="text-center">{t('auth.login.welcomeBack', { email: email })}</p>
              <p className="text-center text-sm mt-1">{t('auth.login.continueRegistration')}</p>
            </div>
            <TeamCreation onSubmit={handleTeamCreation} email={email} />
          </>
        );

      case 'payment':
        return <CheckoutForm email={email} teamData={teamData} />;

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-customBackground p-6 flex flex-col items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('register.title')}</h2>
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}

        {renderCurrentStep()}
      </div>
    </div>
  );
};

export default Register;