import React from 'react';
import { useParams } from 'react-router-dom';

const LevelPage = () => {
  const { level } = useParams(); // Récupère le niveau depuis l'URL
  const levelNumber = parseInt(level, 10);

  // Vérifie si le niveau demandé est entre 1 et 75
  if (isNaN(levelNumber) || levelNumber < 1 || levelNumber > 75) {
    return <div>Niveau non trouvé</div>;
  }

  return (
    <div>
      <h1>Niveau {levelNumber}</h1>
      <p>Contenu du jeu pour le niveau {levelNumber} ici.</p>
    </div>
  );
};

export default LevelPage;
