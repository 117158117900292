const WavesWithBoat = ({ paths = [] }) => {
    return (
      <svg className="responsive-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 -100 1440 600">
        {paths.map((path, index) => (
          <path key={index} fill={path.color} fillOpacity="1" d={path.d}></path>
        ))}
        {/* Rest of the SVG elements, including the boat */}
        <path
          id="wave-path"
          d="M0,64L16,58.7C32,53,64,43,96,58.7C128,75,160,117,192,154.7C224,192,256,224,288,208C320,192,352,128,384,85.3C416,43,448,21,480,32C512,43,544,85,576,101.3C608,117,640,107,672,96C704,85,736,75,768,90.7C800,107,832,149,864,170.7C896,192,928,192,960,192C992,192,1024,192,1056,160C1088,128,1120,64,1152,64C1184,64,1216,128,1248,160C1280,192,1312,192,1344,176C1376,160,1408,128,1424,112"
          style={{ fill: 'none', stroke: 'none' }}
        />
        <g id="boat2" transform="translate(-300, -140)">
          <path
            id="sail"
            d="M300,35 C325,70 325,105 270,105 Q305,70 300,35"
            style={{ stroke: '#fff', strokeWidth: 2, fill: 'white' }}
          />
          <path
            id="sail2"
            d="M320,50 C345,80 345,105 310,105 Q335,80 320,50"
            style={{ stroke: '#fff', strokeWidth: 2, fill: 'white' }}
          />
          <path id="mast" d="M300,40 Q305,80 300,120" style={{ stroke: '#000', strokeWidth: 2 }} />
          <path
            id="hull"
            d="M250,120 Q275,150 300,160 Q325,150 350,120 Q300,140 250,120"
            style={{ stroke: '#fff', strokeWidth: 2, fill: 'white' }}
          />
          <path id="flag1" d="M300,35 Q310,30 315,20 Q310,25 300,20" style={{ fill: 'red' }} />
          <path id="flag2" d="M320,50 Q330,45 335,35 Q330,40 320,35" style={{ fill: '#48D1CC' }} />
          <circle id="window1" cx="280" cy="130" r="5" style={{ stroke: '#000', strokeWidth: 2, fill: 'white' }} />
          <circle id="window2" cx="300" cy="130" r="5" style={{ stroke: '#000', strokeWidth: 2, fill: 'white' }} />
          <circle id="window3" cx="320" cy="130" r="5" style={{ stroke: '#000', strokeWidth: 2, fill: 'white' }} />
        </g>
        <animateMotion xlinkHref="#boat2" dur="30s" repeatCount="indefinite">
          <mpath xlinkHref="#wave-path" />
        </animateMotion>
      </svg>
    );
  };
  
  export default WavesWithBoat;