import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, collection, getDoc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import LevelsList from '../components/LevelsList';


const Dashboard = () => {
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchTeamData(user.uid);
      } else {
        setTeamData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchTeamData = async (userId) => {
    const teamDoc = doc(db, 'teams', userId);
    const snapshot = await getDoc(teamDoc);
    if (snapshot.exists()) {
      setTeamData(snapshot.data());
    }
  };

  if (!teamData) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-[#0e1d35]">
      <div className="container mx-auto px-4 py-8">
        {/* Levels List */}
        <LevelsList teamData={teamData} />
      </div>
    </div>
  );
};

export default Dashboard;
