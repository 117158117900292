import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';

const Navbar = ({ setLanguage, user }) => {
  const [teamName, setTeamName] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [rank, setRank] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    // Charger la langue sauvegardée au démarrage
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'teams', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setTeamName(data.name);
            setProfileImageUrl(data.profileImageUrl);
            setTotalPoints(data.points + data.bonusPoints);

            const teamsCollection = collection(db, 'teams');
            onSnapshot(teamsCollection, (snapshot) => {
              const teams = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              teams.sort(
                (a, b) =>
                  b.points + b.bonusPoints - (a.points + a.bonusPoints)
              );
              const userRank =
                teams.findIndex((team) => team.id === currentUser.uid) + 1;
              setRank(userRank);
            });
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données de l'équipe :", error);
        }
      } else {
        setTeamName('');
        setProfileImageUrl(null);
        setRank(null);
        setTotalPoints(0);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
    }
  };

  return (
    <div className="mb-[120px]">
      <nav className="navbar md:fixed md:top-0 md:left-0 md:w-full md:z-50 flex flex-col sm:flex-row items-center justify-between p-4 bg-[#0e1d35] text-white">
        <div className="flex items-center mb-4 sm:mb-0 flex-shrink-0">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="w-[300px] h-[100px] object-contain mr-3"
            />
          </Link>
        </div>

        <div className="flex gap-2 order-1 sm:order-none mb-4 sm:mb-0">
          <button
            onClick={() => handleLanguageChange('en')}
            className={`text-white border border-white rounded px-1.5 py-0.5 text-xs sm:text-sm hover:bg-white hover:bg-opacity-10 transition ${i18n.language === 'en' ? 'bg-white bg-opacity-10' : ''}`}
          >
            EN
          </button>
          <button
            onClick={() => handleLanguageChange('fr')}
            className={`text-white border border-white rounded px-1.5 py-0.5 text-xs sm:text-sm hover:bg-white hover:bg-opacity-10 transition ${i18n.language === 'fr' ? 'bg-white bg-opacity-10' : ''}`}
          >
            FR
          </button>
          <button
            onClick={() => handleLanguageChange('es')}
            className={`text-white border border-white rounded px-1.5 py-0.5 text-xs sm:text-sm hover:bg-white hover:bg-opacity-10 transition ${i18n.language === 'es' ? 'bg-white bg-opacity-10' : ''}`}
          >
            ES
          </button>
        </div>

        <div className="nav-links flex gap-4 order-2">
          <Link to="/" className="hover:underline">
            {t('navbar.home')}
          </Link>
          <Link to="/faq" className="hover:underline">
            {t('navbar.faq')}
          </Link>
          <Link to="/publicleaderboard" className="hover:underline">
            {t('navbar.leaderboard')}
          </Link>
          {user && (
            <Link to="/tableaubord" className="hover:underline">
              {t('navbar.dashboard')}
            </Link>
          )}
        </div>

        <div className="user-actions flex items-center gap-4 order-3">
          {user && teamName && (
            <span className="font-bold mr-4">
              {t('navbar.welcome')}, {teamName}
            </span>
          )}
          {user && (
            <div className="flex gap-2 items-center">
              <div className="text-center">
                <span className="block text-xs">{t('navbar.rank')}</span>
                <strong className="text-lg">{rank || 'N/A'}</strong>
              </div>
              <div className="text-center">
                <span className="block text-xs">{t('navbar.total')}</span>
                <strong className="text-lg">{totalPoints || '0'}</strong>
              </div>
            </div>
          )}
          {user && profileImageUrl && (
            <img
              src={profileImageUrl || 'default-image-url'}
              alt="Profil"
              className="w-8 h-8 rounded-full border-2 border-white mr-4"
            />
          )}
          <div>
            {user ? (
              <button onClick={handleLogout} className="hover:underline">
                {t('navbar.logout')}
              </button>
            ) : (
              <>
                <Link to="/register" className="hover:underline mr-4">
                  {t('navbar.register')}
                </Link>
                <Link to="/login" className="hover:underline">
                  {t('navbar.login')}
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;