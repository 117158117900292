import React from 'react';

const WaveSvg = ({ paths }) => {
  return (
    <div className="pt-240"> {/* Ajustez 'pt-24' selon la hauteur de votre navbar */}
      <svg
        className="responsive-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 300"
      >
        <g transform="scale(1, -1) translate(0, -320)">
          {paths.map((path, index) => (
            <path
              key={index}
              fill={path.color}
              fillOpacity="1"
              d={path.d}
            ></path>
          ))}
        </g>
      </svg>
    </div>
  );
};

export default WaveSvg;
