import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import WaveSvg from '../components/WaveSvg.jsx';
import WavesWithBoat from '../components/WavesWithBoat.jsx';


const translations = {
  en: { leaderboard: 'Leaderboard', rank: 'Rank', team: 'Team', country: 'Country', level: 'Level', stage: 'Stage', points: 'Points' },
  fr: { leaderboard: 'Classement', rank: 'Rang', team: 'Équipe', country: 'Pays', level: 'Niveau', stage: 'Étape', points: 'Points' },
  es: { leaderboard: 'Clasificación', rank: 'Rango', team: 'Equipo', country: 'País', level: 'Nivel', stage: 'Etapa', points: 'Puntos' }
};

const PublicLeaderboard = ({ language = 'en' }) => {
  const [teams, setTeams] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const currentTranslation = translations[language] || translations['en'];

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'teams'), (snapshot) => {
      const fetchedTeams = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      fetchedTeams.sort((a, b) => (b.points + b.bonusPoints) - (a.points + a.bonusPoints));
      setTeams(fetchedTeams);
    });

    return () => unsubscribe();
  }, []);

  const openLightbox = (team) => {
    setSelectedTeam(team);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedTeam(null);
  };

  const pathsTop = [
    { color: "#caf0f8", d: "M0,64L16,58.7C32,53,64,43,96,58.7C128,75,160,117,192,154.7C224,192,256,224,288,208C320,192,352,128,384,85.3C416,43,448,21,480,32C512,43,544,85,576,101.3C608,117,640,107,672,96C704,85,736,75,768,90.7C800,107,832,149,864,170.7C896,192,928,192,960,192C992,192,1024,192,1056,160C1088,128,1120,64,1152,64C1184,64,1216,128,1248,160C1280,192,1312,192,1344,176C1376,160,1408,128,1424,112L1440,96L1440,320L0,320Z" },
    { color: "#ade8f4", d: "M0,96L16,90.7C32,85,64,75,96,90.7C128,107,160,149,192,186.7C224,224,256,256,288,240C320,224,352,160,384,117.3C416,75,448,53,480,64C512,75,544,117,576,133.3C608,149,640,139,672,128C704,117,736,107,768,122.7C800,139,832,181,864,202.7C896,224,928,224,960,224C992,224,1024,224,1056,192C1088,160,1120,96,1152,96C1184,96,1216,160,1248,192C1280,224,1312,224,1344,208C1376,192,1408,160,1424,144L1440,128L1440,320L0,320Z" },
    { color: "#90e0ef", d: "M0,128L16,122.7C32,117,64,107,96,122.7C128,139,160,181,192,218.7C224,256,256,288,288,272C320,256,352,192,384,149.3C416,107,448,85,480,96C512,107,544,149,576,165.3C608,181,640,171,672,160C704,149,736,139,768,154.7C800,171,832,213,864,234.7C896,256,928,256,960,256C992,256,1024,256,1056,224C1088,192,1120,128,1152,128C1184,128,1216,192,1248,224C1280,256,1312,256,1344,240C1376,224,1408,192,1424,176L1440,160L1440,320L0,320Z" },
    { color: "#48cae4", d: "M0,160L16,154.7C32,149,64,139,96,154.7C128,171,160,213,192,250.7C224,288,256,320,288,304C320,288,352,224,384,181.3C416,139,448,117,480,128C512,139,544,181,576,197.3C608,213,640,203,672,192C704,181,736,171,768,186.7C800,203,832,245,864,266.7C896,288,928,288,960,288C992,288,1024,288,1056,256C1088,224,1120,160,1152,160C1184,160,1216,224,1248,256C1280,288,1312,288,1344,272C1376,256,1408,224,1424,208L1440,192L1440,320L0,320Z" },
    { color: "#00b4d8", d: "M0,192L16,186.7C32,181,64,171,96,186.7C128,203,160,245,192,282.7C224,320,256,352,288,336C320,320,352,256,384,213.3C416,171,448,149,480,160C512,171,544,213,576,229.3C608,245,640,235,672,224C704,213,736,203,768,218.7C800,235,832,277,864,298.7C896,320,928,320,960,320C992,320,1024,320,1056,288C1088,256,1120,192,1152,192C1184,192,1216,256,1248,288C1280,320,1312,320,1344,304C1376,288,1408,256,1424,240L1440,224L1440,320L0,320Z" },
    { color: "#0096c7", d: "M0,224L16,218.7C32,213,64,203,96,218.7C128,235,160,277,192,314.7C224,352,256,384,288,368C320,352,352,288,384,245.3C416,203,448,181,480,192C512,203,544,245,576,261.3C608,277,640,267,672,256C704,245,736,235,768,250.7C800,267,832,309,864,330.7C896,352,928,352,960,352C992,352,1024,352,1056,320C1088,288,1120,224,1152,224C1184,224,1216,288,1248,320C1280,352,1312,352,1344,336C1376,320,1408,288,1424,272L1440,256L1440,320L0,320Z" },
    { color: "#0077b6", d: "M0,256L16,250.7C32,245,64,235,96,250.7C128,267,160,309,192,346.7C224,384,256,416,288,400C320,384,352,320,384,277.3C416,235,448,213,480,224C512,235,544,277,576,293.3C608,309,640,299,672,288C704,277,736,267,768,282.7C800,299,832,341,864,362.7C896,384,928,384,960,384C992,384,1024,384,1056,352C1088,320,1120,256,1152,256C1184,256,1216,320,1248,352C1280,384,1312,384,1344,368C1376,352,1408,320,1424,304L1440,288L1440,320L0,320Z" },
    { color: "#023e8a", d: "M0,288L16,282.7C32,277,64,267,96,282.7C128,299,160,341,192,378.7C224,416,256,448,288,432C320,416,352,352,384,309.3C416,267,448,245,480,256C512,267,544,309,576,325.3C608,341,640,331,672,320C704,309,736,299,768,314.7C800,331,832,373,864,394.7C896,416,928,416,960,416C992,416,1024,416,1056,384C1088,352,1120,288,1152,288C1184,288,1216,352,1248,384C1280,416,1312,416,1344,400C1376,384,1408,352,1424,336L1440,320L1440,600L0,600Z" },
    { color: "#0e1d35", d: "M0,320L16,314.7C32,309,64,299,96,314.7C128,331,160,373,192,410.7C224,448,256,480,288,464C320,448,352,384,384,341.3C416,299,448,277,480,288C512,299,544,341,576,357.3C608,373,640,363,672,352C704,341,736,331,768,346.7C800,363,832,405,864,426.7C896,448,928,448,960,448C992,448,1024,448,1056,416C1088,384,1120,320,1152,320C1184,320,1216,384,1248,416C1280,448,1312,448,1344,432C1376,416,1408,384,1424,368L1440,352L1440,600L0,600Z" },
  ];

    // Chemins et couleurs pour les vagues en bas avec le voilier
    const pathsBottom = [
      { color: "#caf0f8", d: "M0,64L16,58.7C32,53,64,43,96,58.7C128,75,160,117,192,154.7C224,192,256,224,288,208C320,192,352,128,384,85.3C416,43,448,21,480,32C512,43,544,85,576,101.3C608,117,640,107,672,96C704,85,736,75,768,90.7C800,107,832,149,864,170.7C896,192,928,192,960,192C992,192,1024,192,1056,160C1088,128,1120,64,1152,64C1184,64,1216,128,1248,160C1280,192,1312,192,1344,176C1376,160,1408,128,1424,112L1440,96L1440,600L0,600Z" },
      { color: "#ade8f4", d: "M0,96L16,90.7C32,85,64,75,96,90.7C128,107,160,149,192,186.7C224,224,256,256,288,240C320,224,352,160,384,117.3C416,75,448,53,480,64C512,75,544,117,576,133.3C608,149,640,139,672,128C704,117,736,107,768,122.7C800,139,832,181,864,202.7C896,224,928,224,960,224C992,224,1024,224,1056,192C1088,160,1120,96,1152,96C1184,96,1216,160,1248,192C1280,224,1312,224,1344,208C1376,192,1408,160,1424,144L1440,128L1440,600L0,600Z" },
      { color: "#90e0ef", d: "M0,128L16,122.7C32,117,64,107,96,122.7C128,139,160,181,192,218.7C224,256,256,288,288,272C320,256,352,192,384,149.3C416,107,448,85,480,96C512,107,544,149,576,165.3C608,181,640,171,672,160C704,149,736,139,768,154.7C800,171,832,213,864,234.7C896,256,928,256,960,256C992,256,1024,256,1056,224C1088,192,1120,128,1152,128C1184,128,1216,192,1248,224C1280,256,1312,256,1344,240C1376,224,1408,192,1424,176L1440,160L1440,600L0,600Z" },
      { color: "#48cae4", d: "M0,160L16,154.7C32,149,64,139,96,154.7C128,171,160,213,192,250.7C224,288,256,320,288,304C320,288,352,224,384,181.3C416,139,448,117,480,128C512,139,544,181,576,197.3C608,213,640,203,672,192C704,181,736,171,768,186.7C800,203,832,245,864,266.7C896,288,928,288,960,288C992,288,1024,288,1056,256C1088,224,1120,160,1152,160C1184,160,1216,224,1248,256C1280,288,1312,288,1344,272C1376,256,1408,224,1424,208L1440,192L1440,600L0,600Z" },
      { color: "#00b4d8", d: "M0,192L16,186.7C32,181,64,171,96,186.7C128,203,160,245,192,282.7C224,320,256,352,288,336C320,320,352,256,384,213.3C416,171,448,149,480,160C512,171,544,213,576,229.3C608,245,640,235,672,224C704,213,736,203,768,218.7C800,235,832,277,864,298.7C896,320,928,320,960,320C992,320,1024,320,1056,288C1088,256,1120,192,1152,192C1184,192,1216,256,1248,288C1280,320,1312,320,1344,304C1376,288,1408,256,1424,240L1440,224L1440,600L0,600Z" },
      { color: "#0096c7", d: "M0,224L16,218.7C32,213,64,203,96,218.7C128,235,160,277,192,314.7C224,352,256,384,288,368C320,352,352,288,384,245.3C416,203,448,181,480,192C512,203,544,245,576,261.3C608,277,640,267,672,256C704,245,736,235,768,250.7C800,267,832,309,864,330.7C896,352,928,352,960,352C992,352,1024,352,1056,320C1088,288,1120,224,1152,224C1184,224,1216,288,1248,320C1280,352,1312,352,1344,336C1376,320,1408,288,1424,272L1440,256L1440,600L0,600Z" },
      { color: "#0077b6", d: "M0,256L16,250.7C32,245,64,235,96,250.7C128,267,160,309,192,346.7C224,384,256,416,288,400C320,384,352,320,384,277.3C416,235,448,213,480,224C512,235,544,277,576,293.3C608,309,640,299,672,288C704,277,736,267,768,282.7C800,299,832,341,864,362.7C896,384,928,384,960,384C992,384,1024,384,1056,352C1088,320,1120,256,1152,256C1184,256,1216,320,1248,352C1280,384,1312,384,1344,368C1376,352,1408,320,1424,304L1440,288L1440,600L0,600Z" },
      { color: "#023e8a", d: "M0,288L16,282.7C32,277,64,267,96,282.7C128,299,160,341,192,378.7C224,416,256,448,288,432C320,416,352,352,384,309.3C416,267,448,245,480,256C512,267,544,309,576,325.3C608,341,640,331,672,320C704,309,736,299,768,314.7C800,331,832,373,864,394.7C896,416,928,416,960,416C992,416,1024,416,1056,384C1088,352,1120,288,1152,288C1184,288,1216,352,1248,384C1280,416,1312,416,1344,400C1376,384,1408,352,1424,336L1440,320L1440,600L0,600Z" },
      { color: "#0e1d35", d: "M0,320L16,314.7C32,309,64,299,96,314.7C128,331,160,373,192,410.7C224,448,256,480,288,464C320,448,352,384,384,341.3C416,299,448,277,480,288C512,299,544,341,576,357.3C608,373,640,363,672,352C704,341,736,331,768,346.7C800,363,832,405,864,426.7C896,448,928,448,960,448C992,448,1024,448,1056,416C1088,384,1120,320,1152,320C1184,320,1216,384,1248,416C1280,448,1312,448,1344,432C1376,416,1408,384,1424,368L1440,352L1440,600L0,600Z" }
    ];

  return (


    <>
    {/* Vagues du haut */}
    <div className="full-width-container waves">
      <div className="svg-waves">
        <WaveSvg paths={pathsTop} />
      </div>
      <div className="container-espatula">
        <img
          src="https://images.squarespace-cdn.com/content/v1/60b922ef8526a44dec014eb0/8caf7170-e1b6-485f-9cf8-c4d0cd70c846/cigogne.png"
          className="bird left-to-right"
          alt="Oiseau volant"
        />
      </div>
    </div>

    
    <div className="min-h-screen bg-[#0e1d35] p-6 flex flex-col items-center">
      <h1 className="text-2xl font-bold text-white mb-4">{currentTranslation.leaderboard}</h1>
      <div className="overflow-x-auto w-full max-w-4xl">
        <table className="table-auto w-full border border-gray-600">
          <thead className="bg-[#1e2a38] text-white">
            <tr>
              <th className="p-3 border-b border-gray-600">{currentTranslation.rank}</th>
              <th className="p-3 border-b border-gray-600">{currentTranslation.team}</th>
              <th className="p-3 border-b border-gray-600">{currentTranslation.country}</th>
              <th className="p-3 border-b border-gray-600">{currentTranslation.level}</th>
              <th className="p-3 border-b border-gray-600">{currentTranslation.stage}</th>
              <th className="p-3 border-b border-gray-600">{currentTranslation.points}</th>
              <th className="p-3 border-b border-gray-600">Image</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <tr key={team.id} className="text-white bg-[#0e1d35] hover:bg-[#122649]">
                <td className="p-3 border-b border-gray-600 text-center">{index + 1}</td>
                <td
                  className="p-3 border-b border-gray-600 text-center cursor-pointer text-[#90e0ef] hover:underline"
                  onClick={() => openLightbox(team)}
                >
                  {team.name}
                </td>
                <td className="p-3 border-b border-gray-600 text-center">{team.country}</td>
                <td className="p-3 border-b border-gray-600 text-center">{team.currentLevel}</td>
                <td className="p-3 border-b border-gray-600 text-center">{team.currentStage}</td>
                <td className="p-3 border-b border-gray-600 text-center">{team.points + team.bonusPoints}</td>
                <td className="p-3 border-b border-gray-600 text-center">
                  <img
                    src={team.profileImageUrl || 'default-image-url'}
                    alt={`Image of ${team.name}`}
                    className="w-12 h-12 rounded-full object-cover cursor-pointer"
                    onClick={() => openLightbox(team)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {lightboxOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative bg-white p-8 rounded-lg shadow-lg w-80 max-h-full overflow-y-auto text-center">
            <button
              onClick={closeLightbox}
              className="absolute top-3 right-3 text-2xl text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            {selectedTeam && (
              <div>
                <img
                  src={selectedTeam.profileImageUrl || 'default-image-url'}
                  alt={`Image of ${selectedTeam.name}`}
                  className="w-24 h-24 rounded-full object-cover mx-auto mb-4"
                />
                <h2 className="text-xl font-semibold mb-2">{selectedTeam.name}</h2>
                <p><strong>Pays:</strong> {selectedTeam.country || 'N/A'}</p>
                <p><strong>Niveau actuel:</strong> {selectedTeam.currentLevel || 'N/A'}</p>
                <p><strong>Étape actuelle:</strong> {selectedTeam.currentStage || 'N/A'}</p>
                <p><strong>Points:</strong> {selectedTeam.points + (selectedTeam.bonusPoints || 0)}</p>
                <p><strong>Joueurs:</strong></p>
                {selectedTeam.players ? selectedTeam.players.map((player, index) => (
                  <p key={index}>
                    {player.playerName} {player.isChild ? `(Enfant, ${player.childAge || 'N/A'} ans)` : ''}
                  </p>
                )) : <p>Aucun joueur</p>}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

    {/* Vagues du bas - ajout */}
    <div className="full-width-container waves2">
      <div className="svg-waves-bottom">
        <WavesWithBoat paths={pathsBottom} />
      </div>
    </div>
  </>
);
};

export default PublicLeaderboard;
