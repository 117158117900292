import React from 'react';

const LevelsList = ({ teamData = {} }) => {
  const { currentLevel = 0, usedLevels = [] } = teamData || {};
  const levels = Array.from({ length: 75 }, (_, i) => i + 1);

  return (
    <div className="flex flex-col items-center w-full max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-white mb-8">Votre progression</h2>
      
      <div className="w-full grid gap-3 md:gap-4">
        {/* Grille responsive avec ratio 1:1 pour chaque élément */}
        <div className="grid grid-cols-5 md:grid-cols-5 lg:grid-cols-10 gap-2 md:gap-3">
          {levels.map((level) => {
            const isCompleted = usedLevels.includes(`level${level}`);
            const isActive = level === currentLevel + 1;
            const isAccessible = isCompleted || isActive || level <= currentLevel;
            
            // Classes pour les styles de base et les styles conditionnels
            const baseStyles = `
              flex 
              items-center 
              justify-center 
              rounded-lg 
              font-bold 
              text-white 
              text-lg 
              shadow-lg 
              transition-transform 
              duration-200
              aspect-square
            `;
            
            const backgroundStyle = isCompleted 
              ? 'bg-emerald-600' 
              : isActive 
                ? 'bg-red-500'
                : 'bg-slate-800';
            
            const hoverStyle = isAccessible 
              ? 'hover:scale-105 cursor-pointer' 
              : 'cursor-default';

            const Content = () => (
              <div className={`${baseStyles} ${backgroundStyle} ${hoverStyle}`}>
                {level}
              </div>
            );

            return (
              <div key={level} className="w-full h-full">
                {isAccessible ? (
                  <a 
                    href={`/niveau/${level}`}
                    className="block w-full h-full no-underline"
                  >
                    <Content />
                  </a>
                ) : (
                  <Content />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LevelsList;
