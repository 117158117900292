import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyATaZXj9SP3RvDAoAJnpkV0vuZrnTxmFgk',
  authDomain: 'rallye-bacalar.firebaseapp.com',
  databaseURL: 'https://rallye-bacalar-default-rtdb.firebaseio.com',
  projectId: 'rallye-bacalar',
  storageBucket: 'rallye-bacalar.appspot.com',
  messagingSenderId: '885895977225',
  appId: '1:885895977225:web:1b4d5d37a7fd2717dc1bd8',
  measurementId: 'G-ZG4R0DWWYJ',
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Auth avec persistance de session locale
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

// Initialiser Firestore
const db = getFirestore(app);

export { auth, db };
