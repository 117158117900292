// Chemin : src/components/EnhancedPresentation.jsx

import React, { useState } from 'react';
import { ChevronDown, ArrowLeft, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Import du composant Link

const OverviewCard = ({ icon, title, text }) => {
  const { t } = useTranslation();

  return (
    <div className="group bg-[#ffffff] p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer relative">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-bold text-[#0e1d35] mb-2">{t(title)}</h3>
      <p className="text-[#0e1d35] mb-8 text-lg leading-relaxed">{t(text)}</p>

      <div className="absolute bottom-3 right-3 flex items-center gap-1 text-sm font-medium text-[#e9c46a] group-hover:text-[#f4a261] transition-colors">
        <Plus className="w-4 h-4 group-hover:rotate-180 transition-transform duration-300" />
        {t('learn_more')}
      </div>
    </div>
  );
};

const DetailedView = ({ section, onBack }) => {
  const { t } = useTranslation();

  return (
    <div className="p-8 animate-fadeIn bg-[#ffffff]">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-[#0e1d35] hover:text-[#2a9d8f] mb-8 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
          {t('back_to_overview')}
        </button>

        <div className="bg-[#ffffff] rounded-xl shadow-xl p-8">
          <h2 className="text-3xl font-bold text-[#0e1d35] mb-6">
            {t(section.title)}
          </h2>

          {section.detailedContent.map((content, idx) => (
            <div key={idx} className="mb-8 last:mb-0">
              {content.subtitle && (
                <h3 className="text-2xl font-semibold text-[#2a9d8f] mb-4">
                  {t(content.subtitle)}
                </h3>
              )}

              {content.paragraphs &&
                content.paragraphs.map((paragraph, pIdx) => (
                  <p
                    key={pIdx}
                    className="text-[#0e1d35] mb-4 leading-relaxed text-lg"
                  >
                    {t(paragraph)}
                  </p>
                ))}

              {content.list && (
                <ul className="list-disc list-inside ml-4 text-[#0e1d35] space-y-2 text-lg">
                  {content.list.map((item, iIdx) => (
                    <li key={iIdx}>{t(item)}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}

          {/* Bouton d'appel à l'action */}
          <div className="mt-8 text-center">
            <Link to="/register">
              <button className="bg-[#e76f51] text-white font-bold py-4 px-8 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                {t('register_now')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const IntroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-[#0e1d35] p-8 flex items-center justify-center">
      <div className="max-w-4xl mx-auto text-center">
        <div className="bg-[#ffffff] rounded-xl shadow-xl p-8 md:p-12">
          <h1 className="text-4xl md:text-5xl font-bold text-[#0e1d35] mb-8">
            {t('intro_title')}
          </h1>

          <div className="space-y-6">
            <p className="text-xl md:text-2xl text-[#0e1d35] leading-relaxed">
              {t('intro_paragraph1')}
            </p>

            <p className="text-2xl md:text-3xl font-semibold text-[#2a9d8f]">
              {t('intro_paragraph2')}
            </p>

            <p className="text-xl md:text-2xl text-[#0e1d35] leading-relaxed">
              {t('intro_paragraph3')}
            </p>
          </div>

          {/* Bouton d'appel à l'action */}
          <div className="mt-8">
            <Link to="/register">
              <button className="bg-[#e76f51] text-white font-bold py-4 px-8 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                {t('register_now')}
              </button>
            </Link>
          </div>

          <div className="mt-12 animate-bounce">
            <ChevronDown className="w-8 h-8 text-[#0e1d35] mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

const EnhancedPresentation = () => {
  const { t } = useTranslation();
  const [expandedSection, setExpandedSection] = useState(null);

  const sections = [
    {
      id: 'challenges',
      title: 'section1_title',
      titleColor: 'text-white',
      overview: [
        {
          icon: '🧠',
          title: 'section1_overview1_title',
          text: 'section1_overview1_text',
        },
        {
          icon: '🎭',
          title: 'section1_overview2_title',
          text: 'section1_overview2_text',
        },
        {
          icon: '🎨',
          title: 'section1_overview3_title',
          text: 'section1_overview3_text',
        },
        {
          icon: '🗣️',
          title: 'section1_overview4_title',
          text: 'section1_overview4_text',
        },
        {
          icon: '🔢',
          title: 'section1_overview5_title',
          text: 'section1_overview5_text',
        },
        {
          icon: '🗺️',
          title: 'section1_overview6_title',
          text: 'section1_overview6_text',
        },
      ],
      detailedContent: [
        {
          subtitle: 'section1_detail1_subtitle',
          paragraphs: ['section1_detail1_paragraph1'],
        },
        {
          subtitle: 'section1_detail2_subtitle',
          paragraphs: ['section1_detail2_paragraph1'],
        },
        {
          subtitle: 'section1_detail3_subtitle',
          paragraphs: ['section1_detail3_paragraph1'],
        },
        {
          subtitle: 'section1_detail4_subtitle',
          paragraphs: [
            'section1_detail4_paragraph1',
            'section1_detail4_paragraph2',
          ],
        },
      ],
      bgColor: 'bg-gradient-to-br from-[#0e1d35] to-[#2a9d8f]',
    },
    {
      id: 'format',
      title: 'section2_title',
      titleColor: 'text-[#0e1d35]',
      overview: [
        {
          icon: '🌐',
          title: 'section2_overview1_title',
          text: 'section2_overview1_text',
        },
        {
          icon: '🏰',
          title: 'section2_overview2_title',
          text: 'section2_overview2_text',
        },
        {
          icon: '⛵',
          title: 'section2_overview3_title',
          text: 'section2_overview3_text',
        },
      ],
      detailedContent: [
        {
          subtitle: 'section2_detail1_subtitle',
          paragraphs: [
            'section2_detail1_paragraph1',
            'section2_detail1_paragraph2',
          ],
        },
        {
          subtitle: 'section2_detail2_subtitle',
          paragraphs: [
            'section2_detail2_paragraph1',
            'section2_detail2_paragraph2',
          ],
        },
        {
          subtitle: 'section2_detail3_subtitle',
          paragraphs: [
            'section2_detail3_paragraph1',
            'section2_detail3_paragraph2',
          ],
        },
      ],
      bgColor: 'bg-gradient-to-br from-[#2a9d8f] to-[#e9c46a]',
    },
    {
      id: 'rewards',
      title: 'section3_title',
      titleColor: 'text-[#0e1d35]',
      overview: [
        {
          icon: '🎁',
          title: 'section3_overview1_title',
          text: 'section3_overview1_text',
        },
        {
          icon: '🏆',
          title: 'section3_overview2_title',
          text: 'section3_overview2_text',
        },
        {
          icon: '🎯',
          title: 'section3_overview3_title',
          text: 'section3_overview3_text',
        },
      ],
      detailedContent: [
        {
          subtitle: 'section3_detail1_subtitle',
          paragraphs: ['section3_detail1_paragraph1'],
        },
        {
          subtitle: 'section3_detail2_subtitle',
          paragraphs: ['section3_detail2_paragraph1'],
          list: [
            'section3_detail2_list1',
            'section3_detail2_list2',
            'section3_detail2_list3',
            'section3_detail2_list4',
            'section3_detail2_list5',
          ],
        },
        {
          subtitle: 'section3_detail3_subtitle',
          paragraphs: [
            'section3_detail3_paragraph1',
            'section3_detail3_paragraph2',
            'section3_detail3_paragraph3',
            'section3_detail3_paragraph4',
          ],
        },
      ],
      bgColor: 'bg-gradient-to-br from-[#e9c46a] to-[#f4a261]',
    },
    {
      id: 'signup',
      title: 'section4_title',
      titleColor: 'text-white',
      overview: [
        {
          icon: '💫',
          title: 'section4_overview1_title',
          text: 'section4_overview1_text',
        },
        {
          icon: '🎉',
          title: 'section4_overview2_title',
          text: 'section4_overview2_text',
        },
        {
          icon: '🚀',
          title: 'section4_overview3_title',
          text: 'section4_overview3_text',
        },
      ],
      detailedContent: [
        {
          subtitle: 'section4_detail1_subtitle',
          paragraphs: ['section4_detail1_paragraph1'],
        },
        {
          subtitle: 'section4_detail2_subtitle',
          paragraphs: [
            'section4_detail2_paragraph1',
            'section4_detail2_paragraph2',
          ],
        },
        {
          subtitle: 'section4_detail3_subtitle',
          paragraphs: ['section4_detail3_paragraph1'],
        },
      ],
      bgColor: 'bg-gradient-to-br from-[#f4a261] to-[#0e1d35]',
    },
  ];

  return (
    <div className="w-full">
      <IntroSection />

      {sections.map((section, index) => (
        <div
          key={section.id}
          className={`${section.bgColor} transition-all duration-500`}
        >
          {expandedSection === section.id ? (
            <DetailedView
              section={section}
              onBack={() => setExpandedSection(null)}
            />
          ) : (
            <div className="p-8 relative">
              <div className="max-w-6xl mx-auto">
                <h2
                  className={`text-4xl font-bold text-center mb-12 ${section.titleColor}`}
                >
                  {t(section.title)}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {section.overview.map((item, i) => (
                    <div
                      key={i}
                      onClick={() => setExpandedSection(section.id)}
                    >
                      <OverviewCard {...item} />
                    </div>
                  ))}
                </div>

                {/* Bouton d'appel à l'action pour la section 'signup' */}
                {section.id === 'signup' && (
                  <div className="mt-8 text-center">
                    <Link to="/register">
                      <button className="bg-[#e76f51] text-white font-bold py-4 px-8 rounded-full shadow-lg hover:shadow-xl transition-all duration-300">
                        {t('register_now')}
                      </button>
                    </Link>
                  </div>
                )}
              </div>

              {index < sections.length - 1 && (
                <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
                  <ChevronDown className="w-8 h-8 text-[#0e1d35]" />
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default EnhancedPresentation;
