import React from 'react';

const Footer = () => {
  const footerStyle = {
    // Augmentation de la marge pour ne pas toucher les bords de la fenêtre
    margin: '2em',
  };

  const masonryGridStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  };

  const gridItemStyle = {
    backgroundColor: '#eee',
    marginBottom: '1em',
    width: '23%', // Ajusté pour avoir au moins 4 items par ligne
    height: '150px', // Hauteur fixée pour créer des carrés
    boxSizing: 'border-box',
    marginRight: '1%', // Espacement horizontal entre les items
  };

  return (
    <footer style={footerStyle}>
      <div style={masonryGridStyle}>
        {/* Placeholders carrés */}
        <div style={{ ...gridItemStyle, backgroundColor: '#ccc' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#bbb' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#aaa' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#999' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#888' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#777' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#666' }}></div>
        <div style={{ ...gridItemStyle, backgroundColor: '#555' }}></div>
        {/* Ajoutez d'autres items si nécessaire */}
      </div>
    </footer>
  );
};

export default Footer;
