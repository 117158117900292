import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ email, teamData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch("https://us-central1-rallye-bacalar.cloudfunctions.net/createCheckoutSession", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerEmail: email,
          teamId: teamData?.userId,
          successUrl: `${window.location.origin}/register?session_id={CHECKOUT_SESSION_ID}&team_id=${teamData?.userId}`,
          cancelUrl: `${window.location.origin}/register`
        }),
      });

      if (!response.ok) {
        throw new Error(t('checkoutForm.sessionError'));
      }

      const { sessionUrl } = await response.json();
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error:', error);
      setError(t('checkoutForm.paymentErrorRetry'));
    } finally {
      setLoading(false);
    }
  };

  const formatPrice = (amount) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  };

  const renderTeamSummary = () => {
    if (!teamData) return null;

    return (
      <div className="border-t pt-4 mt-4">
        <h4 className="font-semibold mb-2">{t('checkoutForm.teamDetails')}</h4>
        <ul className="space-y-2 text-sm">
          <li><span className="font-medium">{t('checkoutForm.teamName')}:</span> {teamData.name}</li>
          <li><span className="font-medium">{t('checkoutForm.country')}:</span> {teamData.country}</li>
          <li><span className="font-medium">{t('checkoutForm.players')}:</span> {teamData.players.length}</li>
          <li><span className="font-medium">{t('checkoutForm.tripDate')}:</span> {new Date(teamData.tripDate).toLocaleDateString(teamData.language || 'fr')}</li>
        </ul>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Résumé de la commande */}
      <div className="bg-gray-50 p-6 rounded-lg space-y-4">
        <h3 className="text-xl font-bold mb-4">{t('checkoutForm.summary')}</h3>
        
        {/* Prix et détails */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span>{t('checkoutForm.registrationFee')}</span>
            <span className="font-semibold">{formatPrice(500)}</span>
          </div>
          
          <div className="border-t pt-2">
            <div className="flex justify-between items-center font-bold">
              <span>{t('checkoutForm.total')}</span>
              <span>{formatPrice(500)}</span>
            </div>
          </div>
        </div>

        {/* Détails de l'équipe */}
        {renderTeamSummary()}

        {/* Information de TVA/Taxes si nécessaire */}
    {/* 
<p className="text-sm text-gray-500 mt-2">
  {t('checkoutForm.taxInfo')}
</p> 
*/}
      </div>

      {/* Bouton de paiement */}
      <button
        onClick={handleCheckout}
        disabled={loading}
        className="w-full bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400 flex items-center justify-center"
      >
        {loading ? (
          <>
            <span className="animate-spin mr-2">⏳</span>
            {t('checkoutForm.processing')}
          </>
        ) : (
          t('checkoutForm.proceedToPayment')
        )}
      </button>

      {/* Message d'erreur */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
          <button 
            className="absolute top-0 right-0 p-3"
            onClick={() => setError(null)}
          >
            ×
          </button>
        </div>
      )}

      {/* Note de sécurité */}
      <div className="text-sm text-gray-500 text-center">
        <p>{t('checkoutForm.securePayment')}</p>
        <p className="mt-1">{t('checkoutForm.stripeInfo')}</p>
      </div>
    </div>
  );
};

export default CheckoutForm;