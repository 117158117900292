import React, { useState } from 'react';
import { auth } from '../firebase';
import { getFirestore } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

const TeamCreation = ({ email, onSubmit }) => {
  const { t, i18n } = useTranslation(); // On ajoute i18n

  const [teamName, setTeamName] = useState('');
  const [teamCountry, setTeamCountry] = useState('');
  const [teamPlayers, setTeamPlayers] = useState(1);
  const [tripDate, setTripDate] = useState('');
  const [players, setPlayers] = useState([{ playerName: '', isChild: false, childAge: '' }]);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const db = getFirestore();

  const handlePlayerChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;
    setPlayers(updatedPlayers);
  };

  const handleTeamPlayersChange = (e) => {
    const newCount = parseInt(e.target.value, 10);
    setTeamPlayers(newCount);

    const updatedPlayers = Array.from({ length: newCount }, (_, i) => {
      return players[i] || { playerName: '', isChild: false, childAge: '' };
    });
    setPlayers(updatedPlayers);
  };

  const handleTeamCreation = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError('');

    if (!teamName || !teamCountry || !tripDate) {
      setError(t('teamCreation.errorFieldsRequired'));
      setIsSubmitting(false);
      return;
    }

    try {
      // On utilise directement la langue courante de i18n
      const formData = {
        name: teamName,
        country: teamCountry,
        players: players,
        tripDate: tripDate,
        language: i18n.language // Récupération de la langue actuelle
      };

      await onSubmit(formData);
      
    } catch (error) {
      console.error('Erreur lors de la création de l\'équipe:', error);
      setError(t('teamCreation.errorSaving'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h3 className="text-xl font-bold mb-4">{t('teamCreation.stepTitle')}</h3>
      
      <form onSubmit={handleTeamCreation} className="space-y-4">
        <label htmlFor="team-name">{t('teamCreation.teamNameLabel')}</label>
        <input
          type="text"
          id="team-name"
          placeholder={t('teamCreation.teamNamePlaceholder')}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          required
          className="w-full p-2 border rounded mb-4"
          disabled={isSubmitting}
        />

        <label htmlFor="team-country">{t('teamCreation.teamCountryLabel')}</label>
        <input
          type="text"
          id="team-country"
          placeholder={t('teamCreation.teamCountryPlaceholder')}
          value={teamCountry}
          onChange={(e) => setTeamCountry(e.target.value)}
          required
          className="w-full p-2 border rounded mb-4"
          disabled={isSubmitting}
        />

        <label htmlFor="team-players">{t('teamCreation.teamPlayersLabel')}</label>
        <input
          type="number"
          id="team-players"
          placeholder={t('teamCreation.teamPlayersPlaceholder')}
          value={teamPlayers}
          min="1"
          onChange={handleTeamPlayersChange}
          required
          className="w-full p-2 border rounded mb-4"
          disabled={isSubmitting}
        />

        {players.map((player, index) => (
          <div key={index} className="mb-4 p-4 border rounded">
            <label className="block mb-2">
              {index === 0 ? t('teamCreation.captainNameLabel') : t('teamCreation.playerNameLabel', { index: index + 1 })}
            </label>
            <input
              type="text"
              placeholder={index === 0 ? t('teamCreation.captainNamePlaceholder') : t('teamCreation.playerNamePlaceholder', { index: index + 1 })}
              value={player.playerName}
              onChange={(e) => handlePlayerChange(index, 'playerName', e.target.value)}
              required
              className="w-full p-2 border rounded mb-2"
              disabled={isSubmitting}
            />

            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`isChild-${index}`}
                checked={player.isChild}
                onChange={(e) => handlePlayerChange(index, 'isChild', e.target.checked)}
                className="mr-2"
                disabled={isSubmitting}
              />
              <label htmlFor={`isChild-${index}`}>{t('teamCreation.isChildLabel')}</label>
            </div>

            {player.isChild && (
              <div className="ml-4">
                <label className="block mb-2">{t('teamCreation.childAgeLabel')}</label>
                <input
                  type="number"
                  placeholder={t('teamCreation.childAgePlaceholder')}
                  value={player.childAge}
                  onChange={(e) => handlePlayerChange(index, 'childAge', e.target.value)}
                  required
                  className="w-full p-2 border rounded"
                  disabled={isSubmitting}
                />
              </div>
            )}
          </div>
        ))}

        <label htmlFor="trip-date">{t('teamCreation.tripDateLabel')}</label>
        <input
          type="date"
          id="trip-date"
          value={tripDate}
          onChange={(e) => setTripDate(e.target.value)}
          required
          className="w-full p-2 border rounded mb-4"
          disabled={isSubmitting}
        />

        <button 
          type="submit" 
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-400"
          disabled={isSubmitting}
        >
          {isSubmitting ? t('teamCreation.submitting') : t('teamCreation.submitButton')}
        </button>
      </form>
      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </div>
  );
};

export default TeamCreation;