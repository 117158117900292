import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Navigation bar translations
      navbar: {
        home: "Home",
        faq: "FAQ",
        leaderboard: "Leaderboard",
        dashboard: "Dashboard",
        welcome: "Welcome",
        logout: "Logout",
        register: "Register",
        login: "Login",
        rank: "Rank",
        total: "Total"
      },

      // Authentication
      auth: {
        login: {
          passwordLabel: "Password",
          rememberMe: "Remember me",
          forgotPassword: "Forgot password?",
          emailRequired: "Email is required",
          passwordRequired: "Password is required",
          or: "OR",
          welcomeBack: "Welcome back {{email}}", 
          continueRegistration: "Continue your registration where you left off", 
          verificationError: "Error verifying your account",
          title: "Login",
          googleButton: "Sign in with Google",
          orUseEmail: "OR USE EMAIL",
          emailPlaceholder: "Email",
          passwordPlaceholder: "Password",
          submitButton: "Sign In",
          error: "Login error. Please try again."
        },
        // Registration
        googleSignup: "Sign up with Google",
        emailSignup: "Sign up with email",
        emailError: "Error signing up with email",
        googleError: "Error signing up with Google"
      },

      // Registration process
      register: {
        title: "Register for Bacalar Treasure Rally",
        nextStep: "Next Step"
      },

      // Team Creation
      teamCreation: {
        stepTitle: "Create your team",
        teamNameLabel: "Team Name",
        teamNamePlaceholder: "Enter your team name",
        teamCountryLabel: "Country",
        teamCountryPlaceholder: "Country of origin",
        teamPlayersLabel: "Number of players",
        teamPlayersPlaceholder: "Select number of players",
        captainNameLabel: "Captain Name (The players' names may be fictitious)",
        captainNamePlaceholder: "Team captain's name",
        playerNameLabel: "Player {{index}}",
        playerNamePlaceholder: "Player {{index}} name",
        isChildLabel: "Child?",
        childAgeLabel: "Child's Age (Age allows us to adjust certain challenges and rewards accordingly.)",
        childAgePlaceholder: "Age",
        tripDateLabel: "Planned trip date",
        submitButton: "Create Team",
        submitting: "Creating...",
        errorFieldsRequired: "All fields are required",
        errorSaving: "Error saving team"
      },

      // Checkout Form
      checkoutForm: {
        summary: "Registration Summary",
        description: "Bacalar Treasure Rally Registration",
        totalAmount: "Total Amount",
        teamDetails: "Team Details",
        teamName: "Team",
        country: "Country",
        players: "Players",
        tripDate: "Approximate date of your trip to Bacalar",
        proceedToPayment: "Proceed to Payment",
        processing: "Processing...",
        sessionError: "Error creating payment session",
        paymentErrorRetry: "Payment error. Please try again",
        taxInfo: "VAT included if applicable",
        securePayment: "Secure Payment",
        stripeInfo: "Payment processed by Stripe"
      },

      // Common translations
      common: {
        loading: "Loading...",
        error: "Error",
        success: "Success"
      },

      // Website content
      welcome: "Welcome",
      slogan: "From the sail to the web, the adventure starts at your home!",
      flying_bird: "Flying bird",
      learn_more: "Learn more",
      back_to_overview: "Back to overview",
      register_now: "Register Now",

      // Introduction Section
      intro_title: "The adventure that awaits you",
      intro_paragraph1: "Imagine embarking on an extraordinary adventure where every mouse click brings you closer to a hidden treasure, and every challenge transports you to a fascinating universe.",
      intro_paragraph2: "From the web to the sail, the adventure starts at your home!",
      intro_paragraph3: "The Bacalar Treasures Rally is not just a game; it's a modern odyssey that begins comfortably at home and ends on the sparkling Lagoon of Bacalar.",

      // Section 1 - Challenges
      section1_title: "A Tapestry of Challenges",
      section1_overview1_title: "Knowledge Tests",
      section1_overview1_text: "History, geography, and mysteries of the Yucatán",
      section1_overview2_title: "Word Games",
      section1_overview2_text: "Fascinating Mayan riddles and legends",
      section1_overview3_title: "Creative Challenges",
      section1_overview3_text: "Explore Mayan art and culture",
      section1_overview4_title: "Linguistic Challenges",
      section1_overview4_text: "Discover Spanish and Mayan",
      section1_overview5_title: "Encrypted Puzzles",
      section1_overview5_text: "Brain teasers and logical challenges",
      section1_overview6_title: "Treasure Hunt",
      section1_overview6_text: "Explore the secrets of Bacalar",

      section1_detail1_subtitle: "Knowledge Tests",
      section1_detail1_paragraph1: "Stimulate your intellect with questions highlighting the history, geography, flora, and fauna of the Yucatán.",
      section1_detail2_subtitle: "Word Games",
      section1_detail2_paragraph1: "Sharpen your creative mind by solving riddles about Mayan legends, pirate tales, and sailing.",
      section1_detail3_subtitle: "Creative Challenges",
      section1_detail3_paragraph1: "Awaken the Picasso in you with activities inspired by Mayan art and culture.",
      section1_detail4_subtitle: "Trials and Surprises",
      section1_detail4_paragraph1: "Throughout this extraordinary experience, there will be trials and surprises, a bit crazy but always fun and safe.",
      section1_detail4_paragraph2: "Stay ready for the unexpected, as each step may offer moments of laughter, astonishing discoveries, and memorable memories.",
      // Section 2 - Rally Progress
      section2_title: "Rally Progress",
      section2_overview1_title: "Virtual Part",
      section2_overview1_text: "75 exciting online steps",
      section2_overview2_title: "Exploration of Bacalar",
      section2_overview2_text: "25 steps in the historic city",
      section2_overview3_title: "Sailing Adventure",
      section2_overview3_text: "12 challenges on the magical lagoon",

      section2_detail1_subtitle: "Virtual Part - 75 steps",
      section2_detail1_paragraph1: "This first part of the rally invites you to dive right now into a captivating online adventure. Through fun and enriching trials, you will explore hidden treasures on our website and elsewhere on the Web.",
      section2_detail1_paragraph2: "This virtual experience will allow you to gradually immerse yourself in the rich Mexican, Mayan, and Caribbean culture.",
      section2_detail2_subtitle: "Exploration of Bacalar - 25 steps",
      section2_detail2_paragraph1: "This part of the rally will make you discover the hidden gems of Bacalar's historic center. Plan at least 2 hours to stroll through the city's streets, where each turn holds a surprise.",
      section2_detail2_paragraph2: "Within a radius of about 1 km, set off to discover the secrets of Fort San Felipe and its surroundings.",
      section2_detail3_subtitle: "Grand Finale on a Sailboat - 12 challenges",
      section2_detail3_paragraph1: "The finale takes place on the water! 12 fun challenges testing everything you've learned, adapted to the participants' age.",
      section2_detail3_paragraph2: "Here, on the magnificent Bacalar lagoon, you may have the chance to discover the coveted treasure!",

      // Section 3 - Rewards
      section3_title: "Exciting Rewards",
      section3_overview1_title: "Welcome Bonus",
      section3_overview1_text: "$500 mx immediate discount",
      section3_overview2_title: "Monthly Grand Prizes",
      section3_overview2_text: "Up to $2500 mx to win",
      section3_overview3_title: "Surprise Gifts",
      section3_overview3_text: "Rewards throughout the game",

      section3_detail1_subtitle: "Welcome Gift",
      section3_detail1_paragraph1: "Upon registration, receive a $500 mx discount voucher on a sailing excursion in Bacalar. A great way to start your adventure in style!",
      section3_detail2_subtitle: "Rewards during the Rally",
      section3_detail2_paragraph1: "During your progression, win fantastic prizes:",
      section3_detail2_list1: "Private sailing excursions on the Lagoon",
      section3_detail2_list2: "Kayak or paddle excursions with a guide",
      section3_detail2_list3: "Nights in our partner hotels",
      section3_detail2_list4: "Meals in the best restaurants",
      section3_detail2_list5: "Souvenirs and local products",
      section3_detail3_subtitle: "Monthly Prizes",
      section3_detail3_paragraph1: "Each month, the top three teams receive:",
      section3_detail3_paragraph2: "🥇 1st place: $2500 mx in Amazon Gift Card",
      section3_detail3_paragraph3: "🥈 2nd place: $1000 mx in Amazon Gift Card",
      section3_detail3_paragraph4: "🥉 3rd place: $500 mx in Amazon Gift Card",

      // Section 4 - Join
      section4_title: "Join the Adventure",
      section4_overview1_title: "Special Offer",
      section4_overview1_text: "Only $500 mxn per team",
      section4_overview2_title: "Double Advantage",
      section4_overview2_text: "$500 mxn discount offered",
      section4_overview3_title: "Immediate Start",
      section4_overview3_text: "Start the adventure today",

      section4_detail1_subtitle: "An Exceptional Offer",
      section4_detail1_paragraph1: "For only $500 mxn per team, access a spectacular adventure! This contribution helps create captivating games, offer great prizes, and ensure perfect organization.",
      section4_detail2_subtitle: "Immediate Bonus",
      section4_detail2_paragraph1: "Your registration entitles you to a $500 mxn discount on one of our fabulous excursions!",
      section4_detail2_paragraph2: "In other words, your participation can become free if you join one of our excursions.",
      section4_detail3_subtitle: "Start Now",
      section4_detail3_paragraph1: "Once registered, you're immediately propelled into the adventure. No need to wait; the treasure hunt begins as soon as you're ready!"
    }
  },
  fr: {
    translation: {
      // Navigation bar translations
      
      navbar: {
        home: "Accueil",
        faq: "FAQ",
        leaderboard: "Classement",
        dashboard: "Tableau de bord",
        welcome: "Bienvenue",
        logout: "Déconnexion",
        register: "Inscription",
        login: "Connexion",
        rank: "Rang",
        total: "Total"
      },

      // Authentication
      auth: {
        login: {
          passwordLabel: "Mot de passe",
          rememberMe: "Se souvenir de moi",
          forgotPassword: "Mot de passe oublié ?",
          emailRequired: "L'email est requis",
          passwordRequired: "Le mot de passe est requis",
          or: "OU",
          welcomeBack: "Bon retour {{email}}", 
          continueRegistration: "Poursuivez votre inscription là où vous l'aviez laissée", 
          verificationError: "Erreur lors de la vérification de votre compte",
          title: "Connexion",
          googleButton: "Se connecter avec Google",
          orUseEmail: "OU UTILISER L'EMAIL",
          emailPlaceholder: "Email",
          passwordPlaceholder: "Mot de passe",
          submitButton: "Se connecter",
          error: "Erreur de connexion. Veuillez réessayer."
        },
        // Registration
        googleSignup: "S'inscrire avec Google",
        emailSignup: "S'inscrire avec un email",
        emailError: "Erreur lors de l'inscription avec email",
        googleError: "Erreur lors de l'inscription avec Google"
      },

      // Registration process
      register: {
        title: "Inscription au Rallye Trésors de Bacalar",
        nextStep: "Étape suivante"
      },

      // Team Creation
      teamCreation: {
        stepTitle: "Créez votre équipe",
        teamNameLabel: "Nom de l'équipe",
        teamNamePlaceholder: "Entrez le nom de votre équipe",
        teamCountryLabel: "Pays",
        teamCountryPlaceholder: "Pays d'origine",
        teamPlayersLabel: "Nombre de joueurs",
        teamPlayersPlaceholder: "Sélectionnez le nombre de joueurs",
        captainNameLabel: "Nom du capitaine (Les noms des joueurs peuvent être fictifs)",
        captainNamePlaceholder: "Nom du capitaine de l'équipe",
        playerNameLabel: "Joueur {{index}}",
        playerNamePlaceholder: "Nom du joueur {{index}}",
        isChildLabel: "Enfant ?",
        childAgeLabel: "Âge de l'enfant ( L'âge nous permet d'adapter certaines épreuves et récompenses en conséquence.)",
        childAgePlaceholder: "Âge",
        tripDateLabel: "Date approximative de votre voyage à Bacalar",
        submitButton: "Créer l'équipe",
        submitting: "Création en cours...",
        errorFieldsRequired: "Tous les champs sont requis",
        errorSaving: "Erreur lors de la sauvegarde de l'équipe"
      },

      // Checkout Form
      checkoutForm: {
        summary: "Résumé de votre inscription",
        description: "Inscription au Rallye Trésors de Bacalar",
        totalAmount: "Montant total",
        teamDetails: "Détails de l'équipe",
        teamName: "Équipe",
        country: "Pays",
        players: "Joueurs",
        tripDate: "Date du voyage",
        proceedToPayment: "Procéder au paiement",
        processing: "Traitement en cours...",
        sessionError: "Erreur lors de la création de la session de paiement",
        paymentErrorRetry: "Erreur de paiement. Veuillez réessayer",
        taxInfo: "TVA incluse si applicable",
        securePayment: "Paiement sécurisé",
        stripeInfo: "Paiement traité par Stripe"
      },

      // Common translations
      common: {
        loading: "Chargement...",
        error: "Erreur",
        success: "Succès"
      },

      // Website content
      welcome: "Bienvenue",
      slogan: "De la voile à la toile, l'aventure commence chez vous !",
      flying_bird: "Oiseau volant",
      learn_more: "En savoir plus",
      back_to_overview: "Retour à l'aperçu",
      register_now: "Inscrivez-vous maintenant",

      // Introduction Section
      intro_title: "L'aventure qui vous attend",
      intro_paragraph1: "Imaginez-vous embarquer dans une aventure extraordinaire, où chaque clic de souris vous rapproche d'un trésor caché, et chaque défi relevé vous transporte dans un univers fascinant.",
      intro_paragraph2: "De la toile à la voile, l'aventure commence chez vous !",
      intro_paragraph3: "Le Rallye Trésors de Bacalar n'est pas simplement un jeu, c'est une odyssée moderne qui commence confortablement chez vous et se termine sur la scintillante Lagune de Bacalar.",

      // Section 1 - Challenges
      section1_title: "Une tapisserie de défis",
      section1_overview1_title: "Tests de connaissances",
      section1_overview1_text: "Histoire, géographie et mystères du Yucatán",
      section1_overview2_title: "Jeux de mots",
      section1_overview2_text: "Énigmes et légendes mayas fascinantes",
      section1_overview3_title: "Défis créatifs",
      section1_overview3_text: "Art et culture maya à explorer",
      section1_overview4_title: "Défis linguistiques",
      section1_overview4_text: "Découvrez l'espagnol et le maya",
      section1_overview5_title: "Énigmes chiffrées",
      section1_overview5_text: "Casse-têtes et défis logiques",
      section1_overview6_title: "Chasse aux trésors",
      section1_overview6_text: "Explorez les secrets de Bacalar",
      section1_detail1_subtitle: "Tests de connaissances",
      section1_detail1_paragraph1: "Stimulez votre intellect avec des questions qui mettent en valeur l'histoire, la géographie, la faune et la flore du Yucatán.",
      section1_detail2_subtitle: "Jeux de mots",
      section1_detail2_paragraph1: "Aiguisez votre esprit créatif en résolvant des énigmes autour des légendes mayas, des récits de pirates et de la navigation à la voile.",
      section1_detail3_subtitle: "Défis créatifs",
      section1_detail3_paragraph1: "Réveillez le Picasso en vous avec des activités inspirées par l'art et la culture maya.",
      section1_detail4_subtitle: "Épreuves et Surprises",
      section1_detail4_paragraph1: "Tout au long de cette expérience hors du commun, il y aura des épreuves et des surprises, des défis un peu fous mais toujours amusants et sécurisés.",
      section1_detail4_paragraph2: "Restez prêts pour l'inattendu, car chaque étape peut vous réserver des moments de rires, des découvertes étonnantes et des souvenirs mémorables.",

      // Section 2 - Rally Progress
      section2_title: "Déroulement du Rallye",
      section2_overview1_title: "Partie Virtuelle",
      section2_overview1_text: "75 étapes passionnantes en ligne",
      section2_overview2_title: "Exploration de Bacalar",
      section2_overview2_text: "25 étapes dans la ville historique",
      section2_overview3_title: "Aventure en Voilier",
      section2_overview3_text: "12 défis sur la lagune magique",

      section2_detail1_subtitle: "Partie Virtuelle - 75 étapes",
      section2_detail1_paragraph1: "Cette première partie du rallye vous invite à plonger dès maintenant dans une aventure en ligne captivante. À travers des épreuves ludiques et enrichissantes, vous explorerez des trésors cachés sur notre site Internet et ailleurs sur le Web.",
      section2_detail1_paragraph2: "Cette expérience virtuelle vous permettra de vous immerger progressivement dans la riche culture mexicaine, maya, et caribéenne.",
      section2_detail2_subtitle: "Exploration de Bacalar - 25 étapes",
      section2_detail2_paragraph1: "Cette partie du rallye vous fera découvrir les joyaux cachés du centre historique de Bacalar. Prévoyez au minimum 2 heures pour parcourir les rues de la ville, où chaque détour vous réserve une surprise.",
      section2_detail2_paragraph2: "Dans un rayon d'environ 1 km, partez à la découverte des secrets du Fort San Felipe et de ses environs.",
      section2_detail3_subtitle: "Grande Finale en Voilier - 12 épreuves",
      section2_detail3_paragraph1: "La finale se déroule sur l'eau ! 12 épreuves amusantes mettant à l'épreuve tout ce que vous aurez appris, adaptées à l'âge des participants.",
      section2_detail3_paragraph2: "C'est ici, sur la magnifique lagune de Bacalar, que vous aurez peut-être la chance de découvrir le trésor tant convoité !",

      // Section 3 - Rewards
      section3_title: "Récompenses Excitantes",
      section3_overview1_title: "Bonus de Bienvenue",
      section3_overview1_text: "$500 mx de réduction immédiate",
      section3_overview2_title: "Grands Prix Mensuels",
      section3_overview2_text: "Jusqu'à $2500 mx à gagner",
      section3_overview3_title: "Cadeaux Surprises",
      section3_overview3_text: "Des récompenses tout au long du jeu",

      section3_detail1_subtitle: "Cadeau de Bienvenue",
      section3_detail1_paragraph1: "Dès votre inscription, recevez un bon de réduction de $500 mx sur une excursion en voilier à Bacalar. Un excellent moyen de commencer votre aventure avec style !",
      section3_detail2_subtitle: "Récompenses pendant le Rallye",
      section3_detail2_paragraph1: "Pendant votre progression, gagnez des prix fantastiques :",
      section3_detail2_list1: "Excursions privées en voilier sur la Lagune",
      section3_detail2_list2: "Excursions en kayak ou paddle avec guide",
      section3_detail2_list3: "Nuits dans nos hôtels partenaires",
      section3_detail2_list4: "Repas dans les meilleurs restaurants",
      section3_detail2_list5: "Souvenirs et produits locaux",
      section3_detail3_subtitle: "Prix Mensuels",
      section3_detail3_paragraph1: "Chaque mois, les trois meilleures équipes reçoivent :",
      section3_detail3_paragraph2: "🥇 1ère place : $2500 mx en Carte-Cadeau Amazon",
      section3_detail3_paragraph3: "🥈 2e place : $1000 mx en Carte-Cadeau Amazon",
      section3_detail3_paragraph4: "🥉 3e place : $500 mx en Carte-Cadeau Amazon",

      // Section 4 - Join
      section4_title: "Rejoignez l'Aventure",
      section4_overview1_title: "Offre Spéciale",
      section4_overview1_text: "Seulement $500 mxn par équipe",
      section4_overview2_title: "Double Avantage",
      section4_overview2_text: "$500 mxn de réduction offerts",
      section4_overview3_title: "Départ Immédiat",
      section4_overview3_text: "Commencez l'aventure aujourd'hui",

      section4_detail1_subtitle: "Une Offre Exceptionnelle",
      section4_detail1_paragraph1: "Pour seulement $500 mxn par équipe, accédez à une aventure spectaculaire ! Cette contribution permet de créer des jeux captivants, d'offrir des prix formidables, et d'assurer une organisation parfaite.",
      section4_detail2_subtitle: "Bonus Immédiat",
      section4_detail2_paragraph1: "Votre inscription vous donne droit à une réduction de $500 mxn sur l'une de nos fabuleuses excursions !",
      section4_detail2_paragraph2: "En d'autres termes, votre participation peut devenir gratuite si vous participez à l'une de nos excursions.",
      section4_detail3_subtitle: "Commencez Maintenant",
      section4_detail3_paragraph1: "Une fois inscrits, vous êtes immédiatement propulsés dans l'aventure. Plus besoin d'attendre, la chasse aux trésors commence dès que vous êtes prêts !"
    }
  },
  es: {
    translation: {
      // Navigation bar translations
      navbar: {
        home: "Inicio",
        faq: "FAQ",
        leaderboard: "Clasificación",
        dashboard: "Tablero",
        welcome: "Bienvenido",
        logout: "Cerrar sesión",
        register: "Registro",
        login: "Iniciar sesión",
        rank: "Rango",
        total: "Total"
      },

      // Authentication
      auth: {
        login: {
          passwordLabel: "Contraseña",
          rememberMe: "Recuérdame",
          forgotPassword: "¿Olvidaste tu contraseña?",
          emailRequired: "El email es requerido",
          passwordRequired: "La contraseña es requerida",
          or: "O",
          welcomeBack: "Bienvenido de nuevo {{email}}",
          continueRegistration: "Continúe su registro donde lo dejó",
          verificationError: "Error al verificar su cuenta",
          title: "Iniciar sesión",
          googleButton: "Iniciar sesión con Google",
          orUseEmail: "O USAR EMAIL",
          emailPlaceholder: "Correo electrónico",
          passwordPlaceholder: "Contraseña",
          submitButton: "Iniciar sesión",
          error: "Error de inicio de sesión. Por favor, inténtelo de nuevo."
        },
        // Registration
        googleSignup: "Registrarse con Google",
        emailSignup: "Registrarse con email",
        emailError: "Error al registrarse con email",
        googleError: "Error al registrarse con Google"
      },

      // Registration process
      register: {
        title: "Registro para el Rally del Tesoro de Bacalar",
        nextStep: "Siguiente paso"
      },

      // Team Creation
      teamCreation: {
        stepTitle: "Crea tu equipo",
        teamNameLabel: "Nombre del equipo",
        teamNamePlaceholder: "Ingresa el nombre de tu equipo",
        teamCountryLabel: "País",
        teamCountryPlaceholder: "País de origen",
        teamPlayersLabel: "Número de jugadores",
        teamPlayersPlaceholder: "Selecciona el número de jugadores",
        captainNameLabel: "Nombre del capitán (Los nombres de los jugadores pueden ser ficticios)",
        captainNamePlaceholder: "Nombre del capitán del equipo",
        playerNameLabel: "Jugador {{index}}",
        playerNamePlaceholder: "Nombre del jugador {{index}}",
        isChildLabel: "¿Niño?",
        childAgeLabel: "Edad del niño (La edad nos permite adaptar ciertas pruebas y recompensas en consecuencia.)",
        childAgePlaceholder: "Edad",
        tripDateLabel: "Fecha aproximada de su viaje a Bacalar",
        submitButton: "Crear Equipo",
        submitting: "Creando...",
        errorFieldsRequired: "Todos los campos son requeridos",
        errorSaving: "Error al guardar el equipo"
      },

      // Checkout Form
      checkoutForm: {
        summary: "Resumen del registro",
        description: "Registro para el Rally del Tesoro de Bacalar",
        totalAmount: "Monto total",
        teamDetails: "Detalles del equipo",
        teamName: "Equipo",
        country: "País",
        players: "Jugadores",
        tripDate: "Fecha del viaje",
        proceedToPayment: "Proceder al pago",
        processing: "Procesando...",
        sessionError: "Error al crear la sesión de pago",
        paymentErrorRetry: "Error de pago. Por favor, intente de nuevo",
        taxInfo: "IVA incluido si aplica",
        securePayment: "Pago seguro",
        stripeInfo: "Pago procesado por Stripe"
      },

      // Common translations
      common: {
        loading: "Cargando...",
        error: "Error",
        success: "Éxito"
      },

      // Website content
      welcome: "Bienvenido",
      slogan: "¡De la vela a la web, la aventura comienza en casa!",
      flying_bird: "Pájaro volador",
      learn_more: "Saber más",
      back_to_overview: "Volver al resumen",
      register_now: "Regístrate ahora",

      // Introduction Section
      intro_title: "La aventura que te espera",
      intro_paragraph1: "Imagina embarcarte en una aventura extraordinaria, donde cada clic del ratón te acerca a un tesoro oculto, y cada desafío te transporta a un universo fascinante.",
      intro_paragraph2: "¡De la web a la vela, la aventura comienza en casa!",
      intro_paragraph3: "El Rally Tesoros de Bacalar no es solo un juego; es una odisea moderna que comienza cómodamente en casa y termina en la reluciente Laguna de Bacalar.",

      // Section 1 - Challenges
      section1_title: "Un tapiz de desafíos",
      section1_overview1_title: "Pruebas de conocimiento",
      section1_overview1_text: "Historia, geografía y misterios de Yucatán",
      section1_overview2_title: "Juegos de palabras",
      section1_overview2_text: "Enigmas y leyendas mayas fascinantes",
      section1_overview3_title: "Desafíos creativos",
      section1_overview3_text: "Explora el arte y la cultura maya",
      section1_overview4_title: "Desafíos lingüísticos",
      section1_overview4_text: "Descubre español y maya",
      section1_overview5_title: "Acertijos cifrados",
      section1_overview5_text: "Rompecabezas y desafíos lógicos",
      section1_overview6_title: "Búsqueda del tesoro",
      section1_overview6_text: "Explora los secretos de Bacalar",
      section1_detail1_subtitle: "Pruebas de conocimiento",
      section1_detail1_paragraph1: "Estimula tu intelecto con preguntas que destacan la historia, geografía, flora y fauna de Yucatán.",
      section1_detail2_subtitle: "Juegos de palabras",
      section1_detail2_paragraph1: "Aguza tu mente creativa resolviendo enigmas sobre leyendas mayas, relatos de piratas y navegación a vela.",
      section1_detail3_subtitle: "Desafíos creativos",
      section1_detail3_paragraph1: "Despierta el Picasso que llevas dentro con actividades inspiradas en el arte y la cultura maya.",
      section1_detail4_subtitle: "Pruebas y Sorpresas",
      section1_detail4_paragraph1: "A lo largo de esta experiencia fuera de lo común, habrá pruebas y sorpresas, desafíos un poco locos pero siempre divertidos y seguros.",
      section1_detail4_paragraph2: "Prepárate para lo inesperado, ya que cada paso puede ofrecer momentos de risas, descubrimientos asombrosos y recuerdos memorables.",

      // Section 2 - Rally Progress
      section2_title: "Desarrollo del Rally",
      section2_overview1_title: "Parte Virtual",
      section2_overview1_text: "75 etapas emocionantes en línea",
      section2_overview2_title: "Exploración de Bacalar",
      section2_overview2_text: "25 etapas en la ciudad histórica",
      section2_overview3_title: "Aventura en Velero",
      section2_overview3_text: "12 desafíos en la laguna mágica",

      section2_detail1_subtitle: "Parte Virtual - 75 etapas",
      section2_detail1_paragraph1: "Esta primera parte del rally te invita a sumergirte ahora mismo en una aventura en línea cautivadora. A través de pruebas lúdicas y enriquecedoras, explorarás tesoros ocultos en nuestro sitio web y en otros lugares de la web.",
      section2_detail1_paragraph2: "Esta experiencia virtual te permitirá sumergirte gradualmente en la rica cultura mexicana, maya y caribeña.",
      section2_detail2_subtitle: "Exploración de Bacalar - 25 etapas",
      section2_detail2_paragraph1: "Esta parte del rally te hará descubrir las joyas ocultas del centro histórico de Bacalar. Prevé al menos 2 horas para recorrer las calles de la ciudad, donde cada giro te reserva una sorpresa.",
      section2_detail2_paragraph2: "En un radio de aproximadamente 1 km, emprende el descubrimiento de los secretos del Fuerte San Felipe y sus alrededores.",
      section2_detail3_subtitle: "Gran Final en Velero - 12 desafíos",
      section2_detail3_paragraph1: "¡La final se lleva a cabo en el agua! 12 pruebas divertidas que ponen a prueba todo lo que has aprendido, adaptadas a la edad de los participantes.",
      section2_detail3_paragraph2: "¡Es aquí, en la magnífica laguna de Bacalar, donde quizás tengas la oportunidad de descubrir el tan ansiado tesoro!",

      // Section 3 - Rewards
      section3_title: "Recompensas Emocionantes",
      section3_overview1_title: "Bono de Bienvenida",
      section3_overview1_text: "$500 mx de descuento inmediato",
      section3_overview2_title: "Grandes Premios Mensuales",
      section3_overview2_text: "Hasta $2500 mx para ganar",
      section3_overview3_title: "Regalos Sorpresa",
      section3_overview3_text: "Recompensas a lo largo del juego",

      section3_detail1_subtitle: "Regalo de Bienvenida",
      section3_detail1_paragraph1: "Al registrarte, recibe un cupón de descuento de $500 mx en una excursión en velero en Bacalar. ¡Una excelente manera de comenzar tu aventura con estilo!",
      section3_detail2_subtitle: "Recompensas durante el Rally",
      section3_detail2_paragraph1: "Durante tu progresión, gana fantásticos premios:",
      section3_detail2_list1: "Excursiones privadas en velero por la Laguna",
      section3_detail2_list2: "Excursiones en kayak o paddle con guía",
      section3_detail2_list3: "Noches en nuestros hoteles asociados",
      section3_detail2_list4: "Comidas en los mejores restaurantes",
      section3_detail2_list5: "Souvenirs y productos locales",
      section3_detail3_subtitle: "Premios Mensuales",
      section3_detail3_paragraph1: "Cada mes, los tres mejores equipos reciben:",
      section3_detail3_paragraph2: "🥇 1er lugar: $2500 mx en Tarjeta de Regalo Amazon",
      section3_detail3_paragraph3: "🥈 2º lugar: $1000 mx en Tarjeta de Regalo Amazon",
      section3_detail3_paragraph4: "🥉 3er lugar: $500 mx en Tarjeta de Regalo Amazon",

      // Section 4 - Join
      section4_title: "Únete a la Aventura",
      section4_overview1_title: "Oferta Especial",
      section4_overview1_text: "Solo $500 mxn por equipo",
      section4_overview2_title: "Doble Ventaja",
      section4_overview2_text: "$500 mxn de descuento ofrecidos",
      section4_overview3_title: "Inicio Inmediato",
      section4_overview3_text: "Comienza la aventura hoy",

      section4_detail1_subtitle: "Una Oferta Excepcional",
      section4_detail1_paragraph1: "¡Por solo $500 mxn por equipo, accede a una aventura espectacular! Esta contribución ayuda a crear juegos cautivadores, ofrecer premios fantásticos y asegurar una organización perfecta.",
      section4_detail2_subtitle: "Bono Inmediato",
      section4_detail2_paragraph1: "¡Tu inscripción te da derecho a un descuento de $500 mxn en una de nuestras fabulosas excursiones!",
      section4_detail2_paragraph2: "En otras palabras, tu participación puede volverse gratuita si participas en una de nuestras excursiones.",
      section4_detail3_subtitle: "Comienza Ahora",
      section4_detail3_paragraph1: "Una vez registrados, eres inmediatamente impulsado a la aventura. ¡No necesitas esperar, la búsqueda del tesoro comienza tan pronto como estés listo!"
    }
  }
}; // Fin de l'objet resources

// Initialisation de i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr', // Langue par défaut
    fallbackLng: 'fr', // Langue de repli si une traduction est manquante
    interpolation: {
      escapeValue: false // React fait déjà l'échappement
    },
    react: {
      useSuspense: true // Recommandé pour React
    },
    // Options supplémentaires pour une meilleure gestion des traductions
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    returnEmptyString: false, // Ne pas retourner de chaîne vide si la traduction est manquante
    returnNull: false, // Ne pas retourner null si la traduction est manquante
    keySeparator: '.' // Séparateur pour les clés imbriquées
  });

export default i18n;