import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PublicLeaderboard from './pages/PublicLeaderboard';
import Navbar from './components/Navbar';
import LevelPage from './pages/LevelPage';
import EnhancedPresentation from "./components/EnhancedPresentation";
import './app.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'fr'
  });

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleLanguageChange = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={{ backgroundColor: '#0e1d35' }}>
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <Navbar setLanguage={handleLanguageChange} user={user} language={language} />
      <main className="min-h-screen" style={{ backgroundColor: '#0e1d35' }}>
        <Routes>
          <Route path="/" element={<Home language={language} />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/publicleaderboard" element={<PublicLeaderboard language={language} />} />
          <Route path="/tableaubord" element={<Dashboard user={user} language={language} />} />
          <Route path="/niveau/:level" element={<LevelPage />} />
          <Route path="/enhanced-presentation" element={<EnhancedPresentation />} />
        </Routes>
      </main>
    </>
  );
};

export default App;