import React from 'react';
import { useTranslation } from 'react-i18next';


const FAQ = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('faq')}</h1>
      <p>{t('faqDescription')}</p>
    </div>
  );
};

export default FAQ;
